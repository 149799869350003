<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <div class='flex'>
                    <div class="vx-col w-full">
                        <vx-card title="Unit Delivered">
                            <div class="vx-row mb-3">
                                <div class="vx-col lg:w-1/4 md:w-1/2  w-full">
                                    <v-select v-model="limitUnitDeliveredComplete" :options="limitOpt" @input='changeLimit'></v-select>
                                </div>
                                <div class="vx-col lg:w-3/4 md:w-1/2 w-full">
                                    <div class='flex align-items-center'>
                                        <small class="mr-2">Start</small>
                                        <flat-pickr :config="flatPickrConfig" class="w-full" v-model="startDateUnitDeliveredComplete" @input='getUnitDeliveredComplete()'></flat-pickr>
                                        <small class='ml-5 mr-2'>End</small>
                                        <flat-pickr :config="flatPickrConfig" class="w-full" v-model="endDateUnitDeliveredComplete"  @input='getUnitDeliveredComplete()'></flat-pickr>
                                    </div>
                                </div>
                            </div>
                            <VueApexCharts type='bar' :options='chartOptionsDe' :series='seriesDealer' />
                        </vx-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <div class='flex'>
                    <div class="vx-col w-full mb-5">
                        <vx-card title="Driver Performance">
                            <div class="vx-row mb-10">
                                <div class="vx-col lg:w-1/2 md:w-1/2  w-full">
                                    <browse-contact
                                        class="w-full"
                                        :isdriver="true"
                                        v-validate="'required'"
                                        name="driver_id"
                                        label="Driver"
                                        v-model="driver_id"
                                        @input='getUnitDeliveredDriver()'
                                    ></browse-contact>
                                </div>
                                <div class="vx-col lg:w-1/2 md:w-1/2 w-full">
                                    <small>Period</small>
                                    <div class='flex align-items-center'>
                                        <flat-pickr :config="flatPickrConfig" class="w-full" v-model="startDateDriver" @input='getUnitDeliveredDriver()'></flat-pickr>
                                        <small class='ml-5 mr-5'>Until</small>
                                        <flat-pickr :config="flatPickrConfig" class="w-full" v-model="endDateDriver"  @input='getUnitDeliveredDriver()'></flat-pickr>
                                    </div>
                                </div>
                            </div>
                            <div class="vx-row mb-3">
                                <div class="vx-col w-full">
                                    <VueApexCharts type='bar' :options='chartOptionsDr' :series='seriesDriver' />
                                </div>
                                <div class="vx-col lg:w-1/2 md:w-1/2  w-full">
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>
             </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import BrowseContact from "@browse/Contact.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
    components:{
        vSelect,
        flatPickr,
        BrowseContact,
        VueApexCharts
    },
    data(){
        return {
            chartOptionsDr : {},
            chartOptionsDe : {},
            options : {},
            seriesDriver : [],
            seriesDealer : [],
            categoryDriver : [],
            categoryDealer : [],
            reportUnitDeliveredComplete : [],
            reportUnitDriver : [],
            startDateUnitDeliveredComplete: null,
            endDateUnitDeliveredComplete: null,
            startDateDriver: null,
            endDateDriver: null,
            limitUnitDeliveredComplete: 10,
            driver_id: null,
            filterData : {},
            is_filter : false,
            idDelete: null,
            currentPage: 1,
            search:null,
            sortKeyDealer:null,
            sortValDealer:null,
            sortKeyDriver:null,
            sortValDriver:null,
            limitOpt: [ 5, 10, 15, 25, 50, 'All'],
            flatPickrConfig: {
                altFormat: 'j M Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            }
        }
    },
    computed:{
        ...mapState({
        data: state => state.dashboard.rows
        })
    },
    watch:{
        search(){
            this.currentPage=1
            this.getUnitDeliveredComplete()
        },
        currentPage(){
            this.getUnitDeliveredComplete()
        }
    },
    methods:{
        ...mapActions({
            dispatchUnitDeliveredComplete: 'dashboard/unit_delivered_complete_dealer',
            dispatchDriver: 'dashboard/unit_delivered_complete_driver'
        }),
        async getUnitDeliveredComplete(){
            let payload = {
                page: this.currentPage,
                search: this.search,
                limit: this.limitUnitDeliveredComplete,
                start_date: this.startDateUnitDeliveredComplete,
                end_date: this.endDateUnitDeliveredComplete,
                sortKey: this.sortKeyDealer,
                sortVal: this.sortValDealer
            }
            payload = Object.assign(payload)
            let {data} = await this.dispatchUnitDeliveredComplete(payload)

            if(data){
                let category = data.map(x => {
                    return x.dealer;
                })
                let series = data.map(x => {
                    return x.unit_delivered;
                })

                this.seriesDealer = [{ name: 'Unit Delivered', data: series }]
                this.categoryDealer = []
                this.categoryDealer = Object.values(category)
                this.chartOptionsDealer()
            }

            this.reportUnitDeliveredComplete = data
        },
        async getUnitDeliveredDriver(){
            let payload = {
                driver_id: this.driver_id,
                start_date: this.startDateDriver,
                end_date: this.endDateDriver,
                sortKey: this.sortKey,
                sortVal: this.sortVal
            }
            payload = Object.assign(payload)
            let {data} = await this.dispatchDriver(payload)

            if(data){
                let category = data.map(x => {
                    return x.driver;
                })
                let series = data.map(x => {
                    return x.unit_delivered;
                })

                this.seriesDriver = [{ name: 'Unit Delivered', data: series }]
                this.categoryDriver = []
                this.categoryDriver = Object.values(category)
                this.chartOptionsDriver()
            }
            this.reportUnitDriver = data
        },
        handleSortUnitDeliveredComplete(key,val){
            this.sortKeyDealer = key
            this.sortValDealer = val
            this.getUnitDeliveredComplete()
        },
        handleSearchUnitDelivered(val){
            this.search = val
        },
        changeLimit(val){
            this.limitUnitDeliveredComplete = val;
            this.getUnitDeliveredComplete()
        },
        handleSortDriver(key,val){
            this.sortKeyDealer = key
            this.sortValDealer = val
            this.getUnitDeliveredDriver()
        },
        handleSearchDriver(val){
            this.search = val
        },
        chartOptionsDriver(){
            this.chartOptionsDr = {
                chart: {
                    height: 350,
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + " Unit(s)";
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px'
                    }
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: this.categoryDriver,
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                title: {
                    text: 'Unit Delivered per Driver',
                    floating: false,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: moment(this.startDateDriver).format('DD MMMM YYYY') + ' - ' + moment(this.endDateDriver).format('DD MMMM YYYY'),
                    align: 'center',
                    floating: false,
                    margin: 0,
                    style: {
                        fontSize:  '12px',
                        fontWeight:  'normal',
                        fontFamily:  undefined,
                        color:  '#9699a2'
                    },
                }
            }
        },
        chartOptionsDealer(){
            this.chartOptionsDe = {
                chart: {
                    height: 'auto',
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + " Unit(s)";
                    },
                    style: {
                        fontSize: '12px'
                    }
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: this.categoryDealer,
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                title: {
                    text: 'Unit Delivered per Dealer',
                    floating: false,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: moment(this.startDateUnitDeliveredComplete).format('DD MMMM YYYY') + ' - ' + moment(this.endDateUnitDeliveredComplete).format('DD MMMM YYYY'),
                    align: 'center',
                    floating: false,
                    margin: 0,
                    style: {
                        fontSize:  '12px',
                        fontWeight:  'normal',
                        fontFamily:  undefined,
                        color:  '#9699a2'
                    },
                }
            }
        }
    },
    async mounted(){
        const today = moment();

        if(!this.startDateUnitDeliveredComplete){
            this.startDateUnitDeliveredComplete = today.startOf('month').format('YYYY-MM-DD')
        }
        if(!this.endtDateUnitDeliveredComplete){
            this.endDateUnitDeliveredComplete = today.endOf('month').format('YYYY-MM-DD')
        }
        if(!this.startDateDriver){
            this.startDateDriver = today.startOf('month').format('YYYY-MM-DD')
        }
        if(!this.endDateDriver){
            this.endDateDriver = today.endOf('month').format('YYYY-MM-DD')
        }

        await this.getUnitDeliveredComplete()
        await this.getUnitDeliveredDriver()
        this.chartOptionsDealer()
        this.chartOptionsDriver()
        
    }
}
</script>

<style>

</style>
